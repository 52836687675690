import { Component, BaseComponent, Prop, namespace, VariationBase } from '@zento-lib/components';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers/optionLabel';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import rootStore from '@vue-storefront/core/store';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { IVSFReview, KEY as ReviewKey } from 'theme/@types/vsf/stores/review';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';

import type { IProductBoxGrid } from './ProductBoxGrid.d';

const appContextStore = namespace<AppContextStore>(AppContextKey);
const reviewStore = namespace<IVSFReview>(ReviewKey);

/**
 * Product Box Variations
 *
 * Product Box Variations component allowing to order items for product listing.
 **/
@Component({})
@VariationBase
export class ProductBoxGrid extends BaseComponent<IProductBoxGrid, unknown> {
  protected static V = {
    productBox1: () => import('./variations/productBox1/productBox1').then((m) => m.ProductBox1),
    productBox2: () => import('./variations/productBox2/productBox2').then((m) => m.ProductBox2),
    productBox3: () => import('./variations/productBox3/productBox3').then((m) => m.ProductBox3),
    productBox4: () => import('./variations/productBox4/productBox4').then((m) => m.ProductBox4),
    productBox5: () => import('./variations/productBox5/productBox5').then((m) => m.ProductBox5),
    productBox6: () => import('./variations/productBox6/productBox6').then((m) => m.ProductBox6),
    productBox7: () => import('./variations/productBox7/productBox7').then((m) => m.ProductBox7),
  };

  protected static T = {
    qtyAlert: 'qty_alert',
    productOutOfStock: 'product_out_of_stock_label',
    productInStock: 'product_in_stock_label',
    stockManufacturer: 'product_manufacturer_stock_label',
    productLink: 'product_link',
    productBundleLink: 'product_bundle_link',
    productBackOrder: 'product_backorder',
    productPriceInclLabel: 'product_price_incl_label',
    productPriceExclLabel: 'product_price_excl_label',
    productSkuLabel: 'product_sku_label',
    tierPriceInfo: 'product_box_tier_price_info',
    outOfStockCheckLabel: 'out_of_stock_check_label',
  };

  /**
   * Determines product object
   */
  @Prop({ type: Object, required: true })
  product: IProductState | any;

  /**
   * Determines if product has image
   */
  @Prop({ type: Boolean, default: false })
  onlyImage?: boolean;

  /**
   * Determines product position in grid/carousel
   */
  @Prop({ type: Number, default: 0 })
  position?: number;

  /**
   * Determines widgets price display
   */
  @Prop({ type: Boolean, default: false })
  widgetsPrice?: boolean;

  @appContextStore.Getter('isServer')
  isServer: boolean;

  @appContextStore.Getter('isMobile')
  isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  @reviewStore.State('reviewItems')
  protected reviewItems: IVSFReview['state']['reviewItems'];

  protected gdprStore = new GDPRStore();

  /**
   * Determines selected swatch image for main image box
   */
  data() {
    return {
      selectedThumbnail: null,
      categoryConfig: this.extended.$config.zento.theme.category,
      productStock: this.extended.$config.zento.theme.productPage,
      detailsOpen: false,
    };
  }

  /**
   * Determines selected swatch image for main image box
   */
  productThumbnailPath(product: IProductState) {
    let thumbnail =
      product.thumbnail && product.thumbnail !== 'no_selection'
        ? product.thumbnail
        : product.image && product.image !== 'no_selection'
        ? product.image
        : '';

    if (
      product.type_id &&
      product.type_id === 'configurable' &&
      'configurable_children' in product &&
      product.configurable_children.length > 0
    ) {
      thumbnail =
        'image' in product.configurable_children[0]
          ? product.configurable_children[0].image
          : 'thumbnail' in product.configurable_children[0]
          ? product.configurable_children[0].thumbnail
          : '';

      if (!thumbnail || thumbnail === 'no_selection') {
        const childWithImg = product.configurable_children.find((f) => f.image && f.image !== 'no_selection');

        if (childWithImg) {
          thumbnail = childWithImg.image;
        } else {
          thumbnail =
            product.thumbnail && product.thumbnail !== 'no_selection'
              ? product.thumbnail
              : product.image && product.image !== 'no_selection'
              ? product.image
              : '';
        }
      }
    }

    return {
      src: this.$data.selectedThumbnail || thumbnail,
      loading: this.$data.selectedThumbnail || thumbnail,
    };
  }

  /**
   *  TODO: once we modify the wishlist store and bring it to our codebase,
   *  this find should be removed as the store should provide the lookup map,
   *  rather then each component doing a O(N) lookup each time
   */
  get isOnWishlist(): boolean {
    return !!this.$store.state.wishlist.items.find((item) => item.sku === this.product.sku) || false;
  }

  get isOnCompare(): boolean {
    return (
      !!this.$store.state.compare.items.find(
        (item) => item.sku === this.product.sku || item.parentSku === this.product.parentSku,
      ) || false
    );
  }

  onProductPriceUpdate(product: IProductState) {
    if (product.sku === this.product.sku) {
      Object.assign(this.product, product);
    }
  }

  /**
   * Check the element positioning in the viewport
   */
  visibilityChanged(isVisible) {
    if (isVisible) {
      if (this.extended.$config.products.configurableChildrenStockPrefetchDynamic) {
        const skus = [this.product.sku];
        if (
          this.product.type_id === 'configurable' &&
          this.product.configurable_children &&
          this.product.configurable_children.length > 0
        ) {
          for (const confChild of this.product.configurable_children) {
            const cachedItem = rootStore.state.stock.cache[confChild.id];
            if (typeof cachedItem === 'undefined' || cachedItem === null) {
              skus.push(confChild.sku);
            }
          }
          if (skus.length > 0) {
            rootStore.dispatch('stock/list', { skus: skus });
          }
        }
      }
    }
  }

  /**
   * Get product qty and push new data
   */
  protected get availabilityStock() {
    let product = this.product;

    if (product.type_id === 'configurable') {
      let item = product.configurable_children.find((p) => p.stock && p.stock?.qty > 0);

      if (this.extended.$config.zento.theme.stock.msi.enabled && product.stock?.sources) {
        item = product.configurable_children.find(
          (c) => c?.stock?.sources && c?.stock?.sources?.length && c?.stock?.sources?.find((s) => s.status === '1'),
        );
      }

      if (item) {
        product = item;
      }
    }

    if ('stock' in product) {
      const stock = product.stock;

      if (!stock.backorders || stock.backorders === '0') {
        if (
          this.$data.productStock.lowQtyStock &&
          parseFloat(stock.qty) > 0 &&
          parseFloat(stock.qty) < this.$data.productStock.lowQtyStockValue
        ) {
          return this.getTranslation({ id: ProductBoxGrid.T.qtyAlert, data: { qty: parseFloat(stock.qty) } });
        } else if (
          ((!stock.is_in_stock || stock.is_in_stock === '0') && parseFloat(stock.qty) < 1) ||
          !stock.is_in_stock ||
          stock.is_in_stock === '0' ||
          parseFloat(stock.qty) < 1
        ) {
          if (this.extended.$config.zento.theme.category.enableOutOfStockLabel) {
            return this.getTranslation({ id: ProductBoxGrid.T.outOfStockCheckLabel });
          } else {
            return this.getTranslation({ id: ProductBoxGrid.T.productOutOfStock });
          }
        } else if (
          (!this.$data.productStock.lowQtyStock && parseFloat(stock.qty) > 0) ||
          (this.$data.productStock.lowQtyStock && parseFloat(stock.qty) >= this.$data.productStock.lowQtyStockValue)
        ) {
          return this.getTranslation({ id: ProductBoxGrid.T.productInStock });
        }
      } else if (!this.$data.productStock.lowQtyStock) {
        if (parseFloat(stock.qty) > 0) {
          return this.getTranslation({ id: ProductBoxGrid.T.productInStock });
        } else {
          return this.getTranslation({ id: ProductBoxGrid.T.productBackOrder });
        }
      } else if (stock.stock_status === 0 || stock.stock_status === '0') {
        return this.getTranslation({ id: ProductBoxGrid.T.productOutOfStock });
      }
    }

    return false;
  }

  /**
   * Get product qty and push new type
   */
  protected get availabilityStockType() {
    let product = this.product;

    if (product.type_id === 'configurable') {
      let item = product.configurable_children.find((p) => p.stock && p.stock?.qty > 0);

      if (this.extended.$config.zento.theme.stock.msi.enabled && product.stock?.sources) {
        item = product.configurable_children.find(
          (c) => c?.stock?.sources && c?.stock?.sources?.length && c?.stock?.sources?.find((s) => s.status === '1'),
        );
      }

      if (item) {
        product = item;
      }
    }

    if ('stock' in product) {
      const stock = product.stock;

      if (
        this.$data.productStock.lowQtyStock &&
        parseFloat(stock.qty) > 0 &&
        parseFloat(stock.qty) < this.$data.productStock.lowQtyStockValue
      ) {
        return 'stock-notification';
      } else if (
        (!stock.backorders || stock.backorders === '0' || stock.stock_status === 0 || stock.stock_status === '0') &&
        (parseFloat(stock.qty) < 1 || !stock.is_in_stock || stock.is_in_stock === '0')
      ) {
        return 'out-of-stock';
      } else if (
        (!this.$data.productStock.lowQtyStock && parseFloat(stock.qty) > 0) ||
        (this.$data.productStock.lowQtyStock && parseFloat(stock.qty) >= this.$data.productStock.lowQtyStockValue)
      ) {
        return 'in-stock';
      }
    }
  }

  /**
   * Get product options and push new attributes
   */
  get productOptions() {
    const options: {
      [filterType: string]: Array<{ label: string; id: string; attribute_code: string; image: string }>;
    } = {};

    let configOptions = this.product.configurable_options;

    if (configOptions) {
      configOptions = configOptions.slice(0, this.extended.$config.zento.theme.category.productBox.limitSwatches);

      for (const option of configOptions) {
        if (option.values && option.values.length) {
          for (const ov of option.values) {
            const lb = ov.label
              ? ov.label
              : optionLabel(this.$store.state.attribute, {
                  attributeKey: option.attribute_id,
                  searchBy: 'id',
                  optionId: ov.value_index,
                }).label;

            if (lb.toString().trim() !== '') {
              options[option.attribute_code] = options[option.attribute_code] || [];
              options[option.attribute_code].push({
                label: lb,
                id: ov.value_index,
                attribute_code: option.attribute_code,
                image: this.product.image,
              });
            }
          }
        }
      }
    }

    return options;
  }

  /**
   * Get product thumbnails
   */
  get productsThumbnails() {
    return this.extended.$config.zento.images.productsThumbnails;
  }

  /**
   * Get product default filter
   */
  get defaultFilter() {
    // TODO: Bring available filters here and grab the filter name by Object.keys(this.availableFilters)
    return 'color';
  }

  /**
   * Get product link
   */
  get productLink() {
    return formatProductLink(this.product, currentStoreView().storeCode);
  }

  get thumbnail() {
    // TODO: play with the image based on category page filters - eg. when 'red' color is chosen, the image is going to be 'red'
    const thumbnail = this.productThumbnailPath(this.product);

    return this.getThumbnail(
      thumbnail.src,
      this.extended.$config.products.thumbnails.width,
      this.extended.$config.products.thumbnails.height,
    );
  }

  getThumbnail(relativeUrl: string, width: number, height: number) {
    return getThumbnailPath(relativeUrl, width, height);
  }

  /**
   * Determines product position for analytics
   */
  protected sendProductToAnalytics() {
    if (!this.isServer && this.product) {
      this.broadcast('analytics/product-click-track', {
        product: this.product,
        position: this.position + 1,
        list: 'Category',
      });
      this.broadcast('analytics/product-click-track-custom', {
        product: this.product,
        position: this.position + 1,
        list: 'Category',
      });

      /**
       * TODO: should be removed in the future. Custom events added for tefal instance
       * [ZENSP-713][ZENSP-820][ZENSP-386][ZENSP-377][ZENSP-341]
       */
      if (
        (this.$route?.name === 'cms-page' || this.$route?.params?.parentSku) &&
        (this.$route?.params?.slug === 'reduceri-de-toamna' ||
          this.$route?.params?.slug === 'friteuze-cu-aer-cald-tefal-easy-fry' ||
          this.$route?.params?.slug === 'ingenio' ||
          this.$route?.params?.slug === 'multicookere-tefal' ||
          this.$route?.params?.slug === 'bunatatile-de-paste-se-prepara-cu-tefal' ||
          this.$route?.params?.slug === 'tefal-winter-sales' ||
          this.$route?.params?.slug === 'articole-de-bucatarie/oale-si-tigai/gama-de-tigai-tefal-unlimited')
      ) {
        const id = this.gdprStore.allClaims.adWords.id;
        const customId = '/tefal001';
        let secondCustomId = '/tefal0+standard';
        let cat = 'tefal0';
        let type = 'tefal001';

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'multicookere-tefal' || this.$route?.params?.slug === 'ingenio') {
          secondCustomId = '/tefal001+standard';
          cat = 'tefal001';
        }

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'ingenio') {
          secondCustomId = '/tefal000+standard';
          cat = 'tefal000';
        }

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'articole-de-bucatarie/oale-si-tigai/gama-de-tigai-tefal-unlimited') {
          secondCustomId = '/tefal003+standard';
          cat = 'tefal003';
          type = 'tefal001';
        }

        this.broadcast('analytics/custom-product-click-track', {
          id: id,
          customId: customId,
          secondCustomId: secondCustomId,
        });
        this.broadcast('analytics/custom-product-click-track-custom', {
          id: id,
          customId: customId,
          secondCustomId: secondCustomId,
        });

        const hasScript = document.querySelector(`noscript[id="${id}${customId}"]`);

        if (!hasScript) {
          const ns = document.createElement('noscript');
          ns.id = id + customId;
          document.body.appendChild(ns);

          const oImg = document.createElement('img');
          oImg.src =
            // eslint-disable-next-line no-template-curly-in-string
            `https://ad.doubleclick.net/ddm/activity/src=9295049;type=${type};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?`;
          oImg.alt = '';
          oImg.width = 1;
          oImg.height = 1;

          document.getElementById(id + customId).appendChild(oImg);
        }
      }

      /**
       *  TODO: should be removed in the future. Custom events added for rowenta instance
       * [ZENSP-722][ZENSP-762][ZENSP-346]
       */
      if (
        this.$route?.name === 'cms-page' &&
        (this.$route?.params?.slug === 'aniversare_1_an' ||
          this.$route?.params?.slug === 'rowenta-karl-lagerfeld' ||
          this.$route?.params?.slug === 'x-force-flex' ||
          this.$route?.params?.slug === 'rowenta-easter-promo' ||
          this.$route?.params?.slug === 'rowenta-winter-sales')
      ) {
        const id = this.gdprStore.allClaims.adWords.id;
        let customId = '/rowen001';
        let secondCustomId = '/rowen0+standard';
        let cat = 'rowen0';
        let type = 'rowen002';

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'ingrijirea-locuintei/aspiratoare-verticale') {
          customId = '/rowen0';
          secondCustomId = '/rowen00+standard';
          cat = 'rowen00';
          type = 'rowen001';
        }

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'rowenta-karl-lagerfeld') {
          secondCustomId = '/rowen000+standard';
          cat = 'rowen000';
          type = 'rowen001';
        }

        // eslint-disable-line no-use-before-define
        if (
          this.$route?.params?.slug === 'rowenta-easter-promo' ||
          this.$route?.params?.slug === 'aniversare_1_an' ||
          this.$route?.params?.slug === 'rowenta-winter-sales'
        ) {
          type = 'rowen001';
        }

        // eslint-disable-line no-use-before-define
        if (this.$route?.params?.slug === 'rowenta-winter-sales') {
          cat = 'rowen0';
        }

        this.broadcast('analytics/custom-product-click-track', {
          id: id,
          customId: customId,
          secondCustomId: secondCustomId,
        });
        this.broadcast('analytics/custom-product-click-track-custom', {
          id: id,
          customId: customId,
          secondCustomId: secondCustomId,
        });

        const hasScript = document.querySelector(`noscript[id="${id}${customId}"]`);

        if (!hasScript) {
          const ns = document.createElement('noscript');
          ns.id = id + customId;
          document.body.appendChild(ns);

          const oImg = document.createElement('img');
          oImg.src =
            // eslint-disable-next-line no-template-curly-in-string
            `https://ad.doubleclick.net/ddm/activity/src=9259359;type=${type};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?`;
          oImg.alt = '';
          oImg.width = 1;
          oImg.height = 1;

          document.getElementById(id + customId).appendChild(oImg);
        }
      }
    }
  }

  /**
   * Determines product reviews
   */
  protected get filteredReviews() {
    if ('items' in this.reviewItems) {
      const productReviews = this.reviewItems.items.filter((r) => r.product_id === this.product.id);

      return productReviews;
    }

    return [];
  }

  /**
   * Update price and check selected image
   */
  beforeMount() {
    this.sendProductToAnalytics = this.sendProductToAnalytics.bind(this);
    this.onBroadcast('product-after-priceupdate', this.onProductPriceUpdate);
    this.$on('product-box/thumbnail-selected', (swatchUrl: string) => {
      if (this.thumbnail && this.thumbnail.indexOf(swatchUrl) === -1) {
        this.$data.selectedThumbnail = swatchUrl;
      } else {
        this.$data.selectedThumbnail = null;
      }
    });
  }

  beforeDestroy() {
    this.offBroadcast('product-after-priceupdate', this.onProductPriceUpdate);
    this.$off('product-box/thumbnail-selected');
  }

  protected get lowestTierPrice() {
    if (
      this.extended.$config.zento.theme.price.usePriceTiers &&
      'tier_prices' in this.product &&
      this.product.tier_prices.length
    ) {
      const tierValue = (this.product.tier_prices as Array<{
        customer_group_id: number;
        qty: number;
        value: number;
      }>).reduce(
        (acc, tier) => (acc.value < tier.value ? acc : tier),
        {} as { customer_group_id: number; qty: number; value: number },
      ).value;

      if (tierValue && tierValue < this.product.regular_price) {
        return tierValue;
      }
    }

    return null;
  }
}
