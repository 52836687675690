import type { AsVuexStore } from '@zento-lib/components/vuex';

export interface IReview {
  product_id: number;
  title: string;
  detail: string;
  nickname: string;
  review_entity: string;
  review_status: number;
  customer_id?: number | null;
  [k: string]: any;
};

export interface IReviewItems {
  items: IReview[];
  total: number;
  start: number;
  perPage: number;
};

export interface IReviews {
  items: IReview[];
};

export const KEY = 'review';

export type IVSFReview = AsVuexStore<
{
  items: IReviewItems;
  reviewItems: IReviews;
},
undefined,
'list' | 'add',
'review/UPD_REVIEWS' | 'review/UPD_REVIEWS_ITEMS'
>;
